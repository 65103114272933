<template >
  <v-container>
    <v-row>
      <v-col class="mx-2 mx-sm-16">
        <h1 class="mb-4">Hola! {{ getUser.full_name }}</h1>
        <v-card class="pa-4">
          <v-card-title>
            <h4>Informacón personal</h4>
          </v-card-title>
          <v-card-text>
            <v-row v-if="getUser">
              <v-alert
                v-for="(error, idx) in getErrors"
                :key="idx"
                dense
                text
                type="error"
              >
                {{ error }}
              </v-alert>
              <v-col cols="12" md="12">
                <v-text-field
                  disabled
                  hide-details
                  v-model="form.name"
                  label="Nombre completo"
                  placeholder="Nombre completo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  hide-details
                  label="Email"
                  disabled
                  v-model="form.email"
                  placeholder="Correo electrónico"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <!-- <v-text-field
                  hide-details
                  v-model="form.phone"
                  label="Teléfono"
                  placeholder="Teléfono"
                ></v-text-field> -->
                <VuePhoneNumberInput
                  class="mb-8 pt-0 v-text-field py-4 v-text-field--box"
                  :preferred-countries="['MX']"
                  :size="lg"
                  required
                  :color="'#FAB848'"
                  :default-country-code="'MX'"
                  v-model="form.phone"
                  only-countries
                  :translations="{
                    countrySelectorLabel: 'Código del país',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Némero de teléfono',
                    example: 'Ejemplo: ',
                  }"
                />
              </v-col>
            </v-row>
            <v-row v-else class="py-4 justify-center">
              <Loading />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                :loading="isLoading"
                @click="updateUserProfile"
                color="primary"
                depressed
                >Actualizar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  name: "UpdateProfile",
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Editar Perfil",
      };
    },
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      // console.log(vm.getUser);
      if (vm.getUser.UserBroker) {
        vm.$router.go(-1);
      }
    });
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
      },
      isLoading: false,
    };
  },
  components: {
    VuePhoneNumberInput,
  },
  created() {
    this.setErrors("clear");

    this.form.name = this.getUser.full_name;
    this.form.email = this.getUser.email;
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("auth", ["getErrors"]),
  },
  methods: {
    async updateUserProfile() {
      this.isLoading = true;
      await this.updateProfile(this.form);
      this.isLoading = false;
    },
    ...mapActions("auth", ["updateProfile", "setErrors"]),
  },
};
</script>

<style>
</style>
